export const getColorfulSocialIcon = (iconId: string) => {
  switch (iconId) {
    case 'facebook':
      return 'IconColorfulFacebook'
    case 'youtube':
      return 'IconColorfulYoutube'
    case 'instagram':
      return 'IconColorfulInstagram'
    case 'tiktok':
      return 'IconColorfulTikTok'
    case 'twitter':
      return 'IconColorfulTwitter'
    case 'pinterest':
      return 'IconColorfulPinterest'
    case 'twitch':
      return 'IconColorfulTwitch'
    case 'snapchat':
      return 'IconColorfulSnapchat'
    case 'website':
      return 'IconColorfulWebsite'
    default:
      return 'IconColorfulOther'
  }
}
